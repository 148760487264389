import { useState } from 'react';
import { useForm, ValidationError } from '@formspree/react';
import {Stack, Box, TextField, Button, Typography} from '@mui/material';
import './App.css';
import axios from 'axios';

function App() {
  const [firstName, setFirstName] = useState("");
  const [email, setEmail] = useState("");
  const [state, handleSubmit] = useForm("mrgdlzjr");
  if (state.succeeded) {
    axios.get('https://vsbzb7lq4ophiephvlx7hvxsny0igpgb.lambda-url.us-west-2.on.aws', {
      params: {
        name: firstName,
        email: email
      }
    })
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
      return (
        <Box
          sx={{
            '& .MuiTextField-root': { m: 1, width: '30ch' },
            textAlign: "center"
          }}
          noValidate
          autoComplete="off"
        >
          <Box sx={{m:2}}><img src="ennovatech_logo.svg" alt="Ennovatech Solutions"/></Box>
          <Typography variant="subtitle1">Thanks for your interest!</Typography>
          <Typography variant="subtitle1">
            We have sent the brochure to the email address you specified.
          </Typography>
        </Box>
        );
  }
  return (
    <Box
      sx={{
        '& .MuiTextField-root': { m: 1, width: '30ch' },
        textAlign: "center"
      }}
      noValidate
      autoComplete="off"
    >
      <Box sx={{m:2}}><img src="ennovatech_logo.svg" alt="Ennovatech Solutions"/></Box>
      <Typography variant="subtitle1">Welcome to the Ennovatech Solutions!</Typography>
      <Typography variant="subtitle2">Please fill the form below.</Typography>
      <form onSubmit={handleSubmit}>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={0.5}
          sx={{mt:2}}
        >
          <div>
            <TextField
              required
              id="first_name"
              name="first_name"
              label="First Name"
              onChange={(value)=> setFirstName(value.target.value)}
            />
            <ValidationError 
                prefix="first_name" 
                field="first_name"
                errors={state.errors}
            />
          </div>
          <div>  
            <TextField
              id="last_name"
              type="text" 
              name="last_name"
              label="Last Name"
            />
          </div>
          <div>
            <TextField
              id="company"
              name="company"
              label="Company"
            />
          </div>
          <div>  
            <TextField
              id="phone"
              name="phone"
              label="Phone"
            />
          </div>
          <TextField
            required
            id="email"
            type="email" 
            name="email"
            label="Email"
            onChange={(value)=> setEmail(value.target.value)}
            fullWidth
          />
          <ValidationError 
            prefix="Email" 
            field="email"
            errors={state.errors}
          />
          <Button variant="contained" type="submit" disabled={state.submitting}>Submit</Button>
        </Stack>
      </form>
    </Box>
  );
}

export default App;
